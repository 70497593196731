import api from "@/services/api-service";
import querystring from "querystring";

const url = "/pares-biomagneticos";

const find = (query) => {
  return api().post(`${url}/search`, query);
};

const findTrackingPointsByType = (typeId, ids) => {
  return api().get(
    `${url}/type/${typeId}/tracking-points?${querystring.stringify({ ids })}`
  );
};

const findByName = (name) => {
  return api().get(`${url}/name/${name}`);
};

const findRecommendations = (query) => {
  return api().get(`${url}/recommendations/?${querystring.stringify(query)}`);
};

const save = (obj) => {
  return api().post(url, obj);
};

const findOne = (id) => {
  return api().get(`${url}/${id}`);
};

const deactivate = (id) => {
  return api().delete(`${url}/${id}`);
};

export {
  find,
  save,
  findOne,
  findTrackingPointsByType,
  findRecommendations,
  findByName,
  deactivate,
};
