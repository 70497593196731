<template>
  <v-container fluid>
    <perseu-card title="Listagem de pares biomagnéticos">
      <template v-slot:title-right>
        <v-btn
          color="secondary"
          :to="{ name: formParMagneticoPageName }"
        >
          Novo Par
        </v-btn>
      </template>
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <list-filters @submit="list" />
        </filters-panel>
        <v-data-table
          :items="items"
          :headers="headers"
          :options.sync="options"
          :server-items-length="total"
          class="elevation-1"
          multi-sort
        >
          <template v-slot:item.points="{ item }">
            <div class="ma-2">
              <v-avatar size="60" class="mr-2 mb-sm-2 mb-xs-0">
                <v-img :src="item.points.trackingPoint.image" />
              </v-avatar>
              <v-avatar size="60">
                <v-img :src="item.points.impactionPoint.image" />
              </v-avatar>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <table-action-button
              icon="edit"
              text="Editar"
              @click="edit(item)"
            />
            <table-action-button
              v-if="item.active"
              icon="delete"
              @click="deactivatePar(item)"
              text="Desativar par"
            />
            <table-action-button
              v-else
              icon="check"
              @click="activePar(item)"
              text="Ativar par"
            />
            <table-action-button
              icon="reorder"
              text="Ordem dos pontos"
              @click="reorder(item)"
            />
            <table-action-button
              icon="control_point_duplicate"
              text="Duplicar"
              @click="duplicate(item)"
            />
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
  </v-container>
</template>

<script>
import { deactivate, find, save } from "@/services/pares-biomagneticos-service";
import { pagesNames } from "@/router/pages-names";

export default {
  components: {
    ListFilters: () => import("@/components/ParesBiomagneticos/ListFilters"),
  },
  data: () => ({
    headers: [
      { text: "Índice", value: "id", width: "10%" },
      { text: "Pontos", value: "points", width: "20%", sortable: false },
      { text: "Nome", value: "name", width: "50%" },
      { text: "Tipo", value: "type.name" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    items: [],
    item: {},
    total: 0,
  }),
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.pares.filters;
      },
      set(value) {
        this.$store.commit("pares/setFilters", value);
      },
    },
    options: {
      get() {
        return this.$store.state.pares.options;
      },
      set(value) {
        this.$store.commit("pares/setOptions", value);
      },
    },
    formParMagneticoPageName() {
      return pagesNames.PARES_BIOMAGNETICOS_FORM
    }
  },
  methods: {
    async deactivatePar({ id }) {
      try {
        this.$store.dispatch("loading/openDialog");
        await deactivate(id);
        const index = this.findIndexItem(id);
        this.items.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activePar(item) {
      try {
        this.$store.dispatch("loading/openDialog");
        await save({
          ...item,
          active: true,
        });
        const index = this.findIndexItem(item.id);
        this.items.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    findIndexItem(id) {
      return this.items.findIndex((item) => item.id === id);
    },
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        const query = { ...this.options, filter: this.filters };
        let { data } = await find(query);
        this.items = data.items;
        this.total = data.total;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async create() {
      this.item = {};
      this.dialog = true;
    },
    duplicate(item) {
      this.$router.push({
        name: pagesNames.PARES_BIOMAGNETICOS_FORM,
        query: {
          id: item.id,
          duplicate: true,
        },
      });
    },
    addToList(item) {
      this.items.push(item);
    },
    edit(item) {
      this.$router.push({
        name: pagesNames.PARES_BIOMAGNETICOS_FORM,
        query: { id: item.id },
      });
    },
    reorder(item) {
      this.$router.push({
        name: pagesNames.POINTS_ORDER_LIST,
        query: { parId: item.id, typeId: item.type.id },
      });
    },
  },
};
</script>

<style></style>
